import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('view-container',{attrs:{"breadcrumbs":"","scroll-fab":""}},[_c(VCard,{attrs:{"flat":""}},[_c('toolbar',{attrs:{"title":_vm.$t('admin.route.dataTypes')}},[_c(VTextField,{staticClass:"d-none d-md-flex mx-1",staticStyle:{"width":"0"},attrs:{"append-icon":"mdi-magnify","label":_vm.$t('general.search'),"single-line":"","hide-details":"","dense":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c(VDivider,{attrs:{"vertical":""}}),_c(VBtn,{staticClass:"mx-2",attrs:{"small":"","text":"","color":"info"},on:{"click":_vm.recache}},[_c(VIcon,[_vm._v("mdi-database-refresh")]),_c('span',{staticClass:"text--info"},[_vm._v(_vm._s(_vm.$t("general.recache")))])],1)],1),_c(VCardText,{staticClass:"overflow-y-auto px-0 py-0",style:(_vm.getViewHeightStyle(84))},[_c(VDataTable,{attrs:{"dense":"","headers":_vm.headers,"items":_vm.getTypes,"item-key":"id","search":_vm.search,"single-select":""},on:{"click:row":_vm.navigateToSelected},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c(VBtn,{attrs:{"text":"","tile":""}},[_c(VIcon,{staticClass:"mr-2",attrs:{"small":""}},[_vm._v("mdi-cog-outline")]),_vm._v(" "+_vm._s(_vm.$t(item.locale)))],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }